import { FunctionComponent } from 'react';
import Link from 'next/link';

import { Button as ButtonAtom } from '@/atoms';

import { TypeButton } from './types';
import { ButtonVariant } from '@/atoms/button/types';

export const Button: FunctionComponent<TypeButton> = ({
  children,
  className,
  href,
  as,
  onClick,
}: TypeButton) =>
  as === 'a' ? (
    <Link href={href} prefetch={false} passHref legacyBehavior>
      <ButtonAtom as="a" className={className} variant={ButtonVariant.PRIMARY}>
        {children}
      </ButtonAtom>
    </Link>
  ) : (
    <ButtonAtom
      as={as}
      className={className}
      variant={ButtonVariant.PRIMARY}
      {...(onClick && { onClick })}
    >
      {children}
    </ButtonAtom>
  );

Button.displayName = 'Button';
