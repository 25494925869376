import { FunctionComponent } from 'react';
import { default as LinkNext } from 'next/link';

import { Link as LinkAtom } from '@/atoms';

import { TypeLink } from './types';
import { LinkVariant } from '@/atoms/link/types';

export const Link: FunctionComponent<TypeLink> = ({
  href,
  children,
}: TypeLink) => (
  <LinkNext href={href} prefetch={false} passHref legacyBehavior>
    <LinkAtom variant={LinkVariant.POSITIVE} icon="right">
      {children}
    </LinkAtom>
  </LinkNext>
);

Link.displayName = 'Link';
