import { Bannererror } from './Bannererror';
import { Button, Description, Link, Title } from './childs';

export type TypeBannererror = typeof Bannererror & {
  Title: typeof Title;
  Description: typeof Description;
  Button: typeof Button;
  Link: typeof Link;
};

(Bannererror as TypeBannererror).Title = Title;
(Bannererror as TypeBannererror).Description = Description;
(Bannererror as TypeBannererror).Button = Button;
(Bannererror as TypeBannererror).Link = Link;

Title.displayName = 'Bannererror.Title';
Description.displayName = 'Bannererror.Description';
Button.displayName = 'Bannererror.Button';
Link.displayName = 'Bannererror.Link';

export type { TypeBannererrorProps } from './types';
export type { TypeButton, TypeLink, TypeTextProps } from './childs/types';

export default Bannererror as TypeBannererror;
