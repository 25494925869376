import { FunctionComponent } from 'react';

import * as childs from './childs';
import { Container } from '@/atoms';

import { TypeBannererrorProps } from './types';
import { pickChild, pickChilds } from '@/lib/utils';

export const Bannererror: FunctionComponent<TypeBannererrorProps> = ({
  children,
  minHeight,
  notLazy = true,
  style,
  ...props
}: TypeBannererrorProps): JSX.Element => {
  const [, title] = pickChild(children, childs.Title);
  const [, description] = pickChild(children, childs.Description);
  const [, links] = pickChilds(children, childs.Link);
  const [, buttons] = pickChilds(children, childs.Button);

  return (
    <Container
      wrapper
      backgroundLazy={notLazy}
      style={{
        ...style,
        ...(minHeight
          ? {
              minHeight: `${minHeight}px`,
            }
          : {}),
      }}
      {...props}
    >
      <div className="flex flex-col gap-6 md:gap-10">
        <div className="flex flex-col gap-4 md:gap-6">
          {title}
          {description}
        </div>

        <div className="flex flex-col gap-8 md:gap-6 items-center md:flex-row md:justify-start">
          {buttons}
          {links}
        </div>
      </div>
    </Container>
  );
};

Bannererror.displayName = 'Bannererror';
