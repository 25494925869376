import { FunctionComponent } from 'react';
import { TypeTextProps } from './types';

export const Description: FunctionComponent<TypeTextProps> = ({
  children,
}: TypeTextProps) => (
  <div className="text-text-secondary text-body text-body--m text-center md:text-body--l md:text-start md:w-[400px]">
    {children}
  </div>
);

Description.displayName = 'Description';
