import { FunctionComponent } from 'react';
import { TypeTextProps } from './types';

/**
 * Title
 */
export const Title: FunctionComponent<TypeTextProps> = ({
  children,
}: TypeTextProps) => (
  <div className="text-headline text-headline--h3 text-center md:text-headline--h1 md:text-start md:w-[450px]">
    {children}
  </div>
);

Title.displayName = 'Title';
