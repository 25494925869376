import { revalidateTimeout } from '@/lib/constants';
import { routes } from '@/lib/routes';
import { Bannererror } from '@/molecules';

import ImageBg from '@/stories/assets/background-bannererror.jpg';
import { getGlobalElements } from '@/utils/drupal/get-global-elements';
import { GetStaticPropsContext, GetStaticPropsResult } from 'next';
import Layout from '@/components/layouts/Layout';
import { LayoutProps } from '@/components/layouts/BaseLayout';



const Custom404 = ({
  blocks,
}: LayoutProps) => {
  const meta = [{ tag: "meta", attributes: { name: "title", content: "Página no encontrada" } },
  { tag: "meta", attributes: { name: "description", content: "Página no encontrada" } }];
  return (
    <Layout blocks={blocks} theme='ligth' layoutType='base' preFooter={true} meta={meta} canonical="/es/404">
      <div className="md:pt-header">
        <Bannererror
          backgroundColor="#FCF6EE"
          backgroundImage={ImageBg.src}
          classNameWrapper="pt-header md:pt-0"
          className="md:-mb-8 z-[5] min-h-[600px]"
          justify="justify-center"
          notLazy
        >
          <Bannererror.Title>Oops! Página no encontrada.</Bannererror.Title>
          <Bannererror.Description>
            Lo sentimos, no hemos podido encontrar la página que buscas.
          </Bannererror.Description>
          <Bannererror.Button href={routes.home}>
            Volver a la tienda
          </Bannererror.Button>
        </Bannererror>
      </div>
    </Layout>
  )
};

export default Custom404;

export async function getStaticProps(
  context: GetStaticPropsContext
): Promise<GetStaticPropsResult<LayoutProps>> {
  return {
    props: {
      ...(await getGlobalElements(context)),
    },
    revalidate: revalidateTimeout
  };
}